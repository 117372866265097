import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#links",
        "aria-label": "links permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Links`}</h1>
    <p>{`Links to some things about me...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://kentcdodds.com"
        }}>{`Home`}</a>{` - This website :)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://kentcdodds.com/links"
        }}>{`Links`}</a>{` - This page`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/news"
        }}>{`Dustin Davis Mail`}</a>{` - My newsletter!! 💌`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/ama"
        }}>{`AMA`}</a>{` - Ask Me Anything`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/blog"
        }}>{`Blog`}</a>{` - My various musings about JavaScript and software
development`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/twitter"
        }}>{`Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/github"
        }}>{`GitHub`}</a>{` - I have a bunch of projects on here :)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/npm"
        }}>{`npm`}</a>{` - My published open source JavaScript modules`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/info"
        }}>{`Info`}</a>{` - Bio and Photo`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://javascriptair.com"
        }}>{`JavaScript Air`}</a>{` - A podcast I created (I also
started `}<a parentName="li" {...{
          "href": "http://angularair.com"
        }}>{`Angular Air`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/3-mins"
        }}>{`3 Minutes with Kent`}</a>{` - A
`}<a parentName="li" {...{
          "href": "https://briefs.fm"
        }}>{`briefs.fm`}</a>{` podcast I occasionally do`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/talks"
        }}>{`Talks`}</a>{` - Talks I've given`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/workshops"
        }}>{`Workshops`}</a>{` - Workshops I've given`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/egghead"
        }}>{`egghead.io`}</a>{` - My `}<a parentName="li" {...{
          "href": "https://egghead.io"
        }}>{`egghead.io`}</a>{`
instructor page`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://frontendmasters.com/teachers/kentcdodds/"
        }}>{`Frontend Masters Courses`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/tech-chats"
        }}>{`Tech Chats`}</a>{` - A playlist of chats I've had with
awesome people about tech stuff
(`}<a parentName="li" {...{
          "href": "https://github.com/kentcdodds/ama/issues/125"
        }}>{`learn more`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/appearances"
        }}>{`"Appearances"`}</a>{` - A list of podcasts, interviews,
and other places that I've had chats and stuff`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      